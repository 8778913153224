import { makeStyles, ThemeProvider, createTheme } from "@mui/material/styles";
import variables from "./_variables.scss";
import { components } from "./theme.components";

console.log(components);
const colors = variables;
const palette = {
  primary: {
    main: colors.primary,
  },
  secondary: {
    main: colors.secondary,
  },
  highlight: {
    main: colors.highlight,
    light: colors.highlightLight,
  },
  background: {
    main: colors.background,
  },
  highlightLight: {
    main: colors.highlightLight,
  },
  black: {
    main: "#000000",
  },
  white: {
    main: "#ffffff",
  },
};

export const theme = createTheme({
  typography: {
    fontFamily: "cereal",
  },
  palette,
  components,
});
