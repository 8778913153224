import fetchLanguage from '@fetchers/language';
import { useQuery } from '@tanstack/react-query';

const default_iso = 'en';

export const useLanguage = (language_iso) => {

    if(!language_iso || language_iso === undefined){
        language_iso = (navigator.language || navigator.userLanguage || default_iso).slice(0, 2);
    }

    const { data: language, error, isLoading } = useQuery(['language', language_iso], () => fetchLanguage(language_iso), {
          cacheTime: 1000 * 60 * 60 * 24 * 14, //14 days
          staleTime: 1000 * 60 * 60 * 24 * 7, //fresh for 7 days
          retry: 1,
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
        }
    );

    const get = (key) => {
        return language ? (language[key] ?? '-') : null;
    }

    return { lang: get, languageLoading: isLoading, languageError: error, language };

};

export default useLanguage;