import { HomePage } from "@pages/outside/HomePage";
import { LoginPage } from "@features/auth";


import { EntryPage } from "@features/entry";

import { Screenout, Complete } from "@features/endpages";

import OutsidePageLayout from "@layout/containers/OutsidePageLayout";

const entry = [
  {
    path: "/entry/:data",
    element: <EntryPage />,
    exact: true,
  },
];

const endRoutes = [
  {
    path: "/end/screenout",
    element: <Screenout />,
    exact: true,
  },
  {
    path: "/end/complete",
    element: <Complete />,
    exact: true,
  }
];

const publicRoutes = [
  ...entry,
  ...endRoutes,
  {
    path: "/",
    element: <HomePage />,
    exact: true,
  },
  {
    path: "/login",
    element: (
      <OutsidePageLayout>
        <LoginPage />
      </OutsidePageLayout>
    ),
    exact: true,
  },
];

export default publicRoutes;
