import { Container, Box, Toolbar } from "@mui/material";
import { createContext } from "react";
import TopNavigation from "../../navigation/InsideTopNavigation";
import SideBar from "../../navigation/InsideSideBar";
import React, { Fragment } from "react";

const PageLayout = (props) => {
  const { children } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <TopNavigation setOpenSidebar={setOpen} />

      <SideBar open={open} setOpen={setOpen} />

      <Box
        sx={{
          bgcolor: "white.main",
          flexGrow: 1,
        }}
      >
        <Toolbar style={{ minHeight: 70 }} />
        {children}
      </Box>
    </div>
  );
};

export default PageLayout;
