import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import useLanguage from "../../../hooks/language";
import queryString from "query-string";

const Screenout = () => {

    const location = useLocation();
    const { lang: iso } = queryString.parse(location.search);
    const { lang } = useLanguage(iso);

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", 
            background: '#FAFAFA',
        }}>
            <Box sx={{
                textAlign: "center",
                width: "90%",
                maxWidth: 600,
                display: "flex",
                flexDirection: "column",
            }}>
                <Typography variant="h1" color="common.black" fontSize={40}>{lang("endpages")?.screenout?.title}</Typography>
                <Typography variant="p" color="common.black" fontSize={20} mt={1}>{lang("endpages")?.screenout?.undertext}</Typography>
                <Typography variant="caption" color="common.black">{lang("endpages")?.screenout?.subtext}</Typography>
            </Box>
        </Box>
    );
}

export default Screenout;