import DashboardPage from "@pages/inside/DashboardPage";

const privateRoutes = [
  {
    path: "/dashboard",
    element: <DashboardPage />,
    exact: true,
  },
];

export default privateRoutes;
