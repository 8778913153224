import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { NavLink } from "react-router-dom";
import store, { actions } from "@store";
const InsideTopNavigation = (props) => {
  const { pages = [], setOpenSidebar = () => {} } = props;
  return (
    <AppBar position="fixed" color="background">
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <div style={{ flex: 0, display: "flex" }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={() => {
                setOpenSidebar((state) => !state);
              }}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </div>
        {pages.map((page, index) => {
          return (
            <NavLink to={page.path}>
              <Button color="inherit" key={index}>
                {page.title}
              </Button>
            </NavLink>
          );
        })}
        <Button
          color="inherit"
          onClick={() => {
            store.dispatch(actions.logout());
          }}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default InsideTopNavigation;
