import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import store, { persistor } from "@store";
import Router from "@routes";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@style/theme";
import { QueryClientProvider } from "./QueryClientProvider";

const AppProvider = () => {
  return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <QueryClientProvider>
              <Router />
            </QueryClientProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
  );
};

export default AppProvider;
