import "./style/index.scss";

import store, { actions } from "@store";
import { Grid, Container } from "@mui/material";
import PageLayout from "@layout/containers/InsidePageLayout";

const DashboardPage = () => {
  return (
    <PageLayout>
      <Container maxWidth="xl">
        <h1>Dashboard</h1>
      </Container>
    </PageLayout>
  );
};

export default DashboardPage;
