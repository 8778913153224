import { Box, Typography } from "@mui/material";
import queryString from "query-string";
import { useEffect, useState } from "react";
import ConfettiExplosion from 'react-confetti-explosion';
import { useLocation } from "react-router-dom";
import useLanguage from "../../../hooks/language";
import { Helmet } from "react-helmet";


const Complete = () => {

    const location = useLocation();
    const { lang: iso } = queryString.parse(location.search);
    const { lang } = useLanguage(iso);

    const [trigger, setTrigger] = useState(false);
    const confettiSettings = {
        animationLength: 10000,
        loop: true,
        loopPause: 0,
    };

    const runConfetti = () => {
        console.log("Running confetti");
        if(!confettiSettings.loop) return;
        setTrigger(false); // Reset the trigger
        setTimeout(() => {
            setTrigger(true); // Set it back to true after a short delay
        }, 20);
    }

    useEffect(() => {
        runConfetti();
        const interval = setInterval(() => {
            runConfetti();
        }, confettiSettings.animationLength + confettiSettings.loopPause);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", 
            background: 'linear-gradient(24deg, rgba(0,102,37,1) 0%, rgba(49,131,12,1) 47%, rgba(5,181,59,1) 100%)',
            flexDirection: "column",
        }}>
            <Helmet>
                <meta name="theme-color" content="#01B53A" />
            </Helmet>
            {trigger && <ConfettiExplosion force={1} duration={confettiSettings.animationLength} particleCount={100} width={window.innerWidth * 2} height={window.innerHeight * 2} style={s.absoluteCenter}/>}
            <Box sx={{
                textAlign: "center",
                width: "90%",
                display: "flex",
                flexDirection: "column",
            }}>
                <Typography variant="h1" color="common.white" fontSize={40}>{lang("endpages")?.complete?.title}</Typography>
                <Typography variant="p" color="common.white" fontSize={20} mt={1}>{lang("endpages")?.complete?.undertext}</Typography>
            </Box>
        </Box>
    );
}

const s = {
    absoluteCenter: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
};

export default Complete;