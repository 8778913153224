import React, { Fragment } from "react";
import { Box, Drawer, Toolbar } from "@mui/material";

const InsideSideBar = (props) => {
  const { open, setOpen } = props;
  return (
    <Drawer
      sx={{ flexShrink: 0, width: 300, zIndex: 99 }}
      open={open}
      onClose={() => setOpen(false)}
      anchor="left"
    >
      <Toolbar style={{ minHeight: 70 }} />
      <Box sx={{ width: 300, padding: 2 }}>
        <h5>Hej</h5>
      </Box>
    </Drawer>
  );
};

export default InsideSideBar;
