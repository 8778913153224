import httpbuildquery from "@utils/httpbuildquery";
import { axios } from "./axios";
import store from "@store";
export const get = async (url, params = null) => {
  let full_url = url + (params ? "?" + httpbuildquery(params) : "");
  return await axios.get(full_url);
};

export const post = async (url, params = [],token=null) => {
  let headers =  {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  if(token){
    headers['Authorization'] = `Bearer ${token}`;
  }

  return await axios.request({
    url,
    method: "post",
    data: params,
    headers
  });
};

export default { get, post };
 