import { useNavigate } from "react-router-dom";
import LoginForm from "../../components/LoginForm/LoginForm";
import { Container } from "@mui/material";

const LoginPage = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth="sm">
      <div
        style={{
          minHeight: "60vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoginForm
          onLogin={() => {
            return navigate("/dashboard");
          }}
        />
      </div>
    </Container>
  );
};

export default LoginPage;
