import axios from "axios";

const fetchLanguage = async (language) => {
    try{
        const request = await axios.get(`/languages/${language}.json`);
        JSON.parse(JSON.stringify(request.data)); //make sure it's valid json or go to catch (due to that live gives 200 even if not exist due to "js not enabled" when request)
        if (typeof request.data !== 'object' || Array.isArray(request.data)) { //run catch if not object or array
            throw new Error('Invalid JSON structure');
        }
        return request.data;
    } catch (error) {
        if(language !== 'en'){ //fallback to english but only once
            return fetchLanguage('en');
        }
    }
};

export default fetchLanguage;