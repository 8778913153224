import globalpages from "@globalpages";

const globalRoutes = [
  {
    path: "*",
    element: <globalpages.notfound />,
    exact: true,
  },
];

export { globalRoutes };
