import Axios, { AxiosRequestConfig } from "axios";
import * as apiConstants from "@config/api.constants";
import store from "@store";

function authRequestInterceptor(config) {
  const token = store.getState().token;

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
    config.withCredentials = true;
  }

  return config;
}

export const axios = Axios.create({
  baseURL: apiConstants.API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const response = error.response?.data || error.message;

    return Promise.reject({
      type: "error",
      status: error.response?.status || 500,
      response,
    });
  }
);
