import * as constants from "@constants";
import { AppBar, Box, Toolbar, Typography, Button } from "@mui/material";
import { NavLink } from "react-router-dom";

const OutsideTopNavigation = (props) => {
  const { pages = [] } = props;
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="background">
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            <div style={{ flex: 0, display: "flex" }}>
              <NavLink to="/">
                <Typography variant="h6">{constants.BRAND}</Typography>
              </NavLink>
            </div>
          </div>
          {pages.map((page, index) => {
            return (
              <NavLink to={page.path}>
                <Button color="inherit" key={index}>
                  {page.title}
                </Button>
              </NavLink>
            );
          })}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default OutsideTopNavigation;
